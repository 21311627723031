@font-face {
  font-family: "Regular";
  src: local("InterRegular"),
    url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("InterMedium"),
    url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SemiBold";
  src: local("InterSemiBold"),
    url("./fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("InterBold"),
    url("./fonts/Inter-Bold.ttf") format("truetype");
}

body,
html {
  font-family: Regular, sans-serif;
}